/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

label {
	&.required {
		&:after {
			content: "*";
			font-size: 0.8em;
			margin: 0.3em;
			position: relative;
			top: -2px;
		}
	}
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

// Form Control
.form-control {
	border-color: rgba(0, 0, 0, 0.09);
	&:not(.form-control-lg) {
		font-size: 0.75rem;
		line-height: 1.3;
	}

	&:not(.form-control-sm):not(.form-control-lg) {
		font-size: 0.85rem;
		line-height: 1.85;
	}

	&.form-control-focused {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&:focus {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&.error {
		border-color: #a94442;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: #843534;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
		}
	}
}

.page-link {
	border-color: rgba(0, 0, 0, 0.06);
}

// Input Group
.input-group {
	.form-control {
		height: auto;
	}
}

// Input Groups Rounded
.input-group-rounded {
	.form-control {
		border-radius: if-ltr(2rem 0rem 0rem 2rem, 0rem 2rem 2rem 0rem !important);
		border: 0;
		padding-#{$left}: 1rem;
	}
	.input-group-append {
		.btn {
			border-color: rgba(0,0,0,0.09);
			border-radius: if-ltr(0rem 2rem 2rem 0rem, 2rem 0rem 0rem 2rem);
			font-size: 0.7rem;
			padding: 0.87rem;
			border-top: 0;
			border-bottom: 0;
			border-right: 0;
			z-index: 3;
		}
	}
}

// IOS OVERRIDE
// -----------------------------------------------------------------------------
/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	-webkit-appearance: none;
}

// PLACEHOLDERS
// -----------------------------------------------------------------------------
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	@include placeholder-color(#bdbdbd);
}

/* Placeholder Light */
.form-placeholders-light {
	.form-control,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
	textarea {
		@include placeholder-color(#FFF);
	}
}

/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
	line-height: 1.334;
}

.input-group-btn {

	.btn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

}

@include if-rtl() {
	.input-group>.input-group-append>.btn, 
	.input-group>.input-group-append>.input-group-text, 
	.input-group>.input-group-prepend:first-child>.btn:not(:first-child), 
	.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), 
	.input-group>.input-group-prepend:not(:first-child)>.btn, 
	.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
		border-top-right-radius: 0;
    	border-bottom-right-radius: 0;
    	border-top-left-radius: .25rem;
    	border-bottom-left-radius: .25rem;
	}
}

select {
	&.form-control-sm,
	&.form-control-lg {
		line-height: 1;
		height: auto !important;
	}
}

.custom-select-1 {
	position: relative;
	&:before {
		content: '\f107';
		font-family: "Font Awesome 5 Free";
		position: absolute;
		display: block;
		top: 53.5%;
		#{$right}: 14px;
	    color: #212530;
	    font-weight: 900;
	    font-size: 0.9em;
	    pointer-events: none;
	    transform: translate3d(0, -50%, 0);
	}
	select {
	    line-height: 1;
	    font-size: inherit;
	    appearance: none;

	    &::-ms-expand {
	    	display: none;
	    }
	}

	&.custom-select-1-icon-light {
		&:before {
		    color: #FFF;
		}
	}
}

/* Custom Checkbox */
.custom-checkbox-1 {
	position: relative;
	padding: 0 0 0 25px;
	margin-bottom: 7px;
	margin-top: 0;

	&.form-check {
		.form-check-input {
			margin-left: 0;
		}
		.form-check-label {
			padding-left: 0;
		}
	}

	&.checkbox-inline {
		display: inline-block;
		vertical-align: middle;

		.form-group & {
			margin-top: 7px;
			padding-top: 0;
		}
	}

	&:last-child,
	&:last-of-type {
		margin-bottom: 0;
	}

	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 3px;
		margin: -6px 0 0 0;
		z-index: 2;
		cursor: pointer;

		&:checked {
			& + label:after {
				position: absolute;
				display: inline-block;
				font-family: 'Font Awesome 5 Free';
				content: '\F00C';
				top: 50%;
				left: 4px;
				margin-top: -5px;
				font-size: 11px;
				font-weight: 900;
				line-height: 1;
				width: 16px;
				height: 16px;
				color: #333;
			}
		}

		&:disabled {
			cursor: not-allowed;

			&:checked + label:after {
				color: #999;
			}

			& + label {
				cursor: not-allowed;
			}

			& + label:before {
				background-color: #eee;
			}
		}
	}

	label {
		cursor: pointer;
		margin-bottom: 0;
		text-align: left;
		line-height: 1.5;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -9px;
			width: 19px;
			height: 18px;
			display: inline-block;
			border-radius: 2px;
			border: 1px solid #dadada;
			background: #fff;
		}

		& + label.error {
			display: block;
		}
	}
	
	&.checkbox-custom-transparent {
		label {
			&:before {
				background: transparent;
			}
		}

		input[type="checkbox"] {
			&:checked {
				& + label:after {
					color: $color-light;
				}
			}

			&:disabled {
				&:checked + label:after {
					color: #999;
				}
			}
		}
	}
}

/* Custom Checkbox Switch */
.custom-checkbox-switch {
	position: relative;
	appearance: none; 
	outline: none;
	width: 50px; 
	height: 26px;
	background-color: #CCC; 
	border: 1px solid #D9DADC;
	border-radius: 50px; 
	cursor: pointer;
	transition: ease all 300ms;
	&:after {
		content: '';
	    position: absolute;
	    top: 50%;
	    left: 6px;
	    width: 15px;
	    height: 15px;
	    background-color: #FFF;
	    border-radius: 50%;
	    transform: translate3d(0, -50%, 0);
	    transition: ease left 300ms;
	}
	&:checked {
		border-color: #CCC;
		&:after {
			left: 27px;
		}
	}
}

.bootstrap-timepicker-widget {
	input {
		border: 0;
	}
	table {
		td {
			input {
				width: 40px;
			}
		}
	}
}

.fileupload .btn {
    line-height: 20px;
    margin-left: -5px;
}

/* Forms Validations */
label {
	&.valid {
		display: inline-block;
		text-indent: -9999px;
	}

	&.error {
		color: #c10000;
		font-size: 0.9em;
		line-height: 18px;
		padding: 5px 0 0;
		&#captcha-error {
			margin-top: 15px;
		}
	}
}

.form-errors-light {
	.form-control {
		&.error {
			border-color: #FFF;
		}
	}
	label {
		&.error {
			color: #FFF;
		}
	}
}

/* Simple Search */
.simple-search {
	.form-control {
		border-radius: if-ltr(1.3rem 0 0 1.3rem, 0 1.3rem 1.3rem 0 !important);
		border-color: #EEE;
		border-#{$right}: 0;

		&:focus {
			box-shadow: none;
		}
	}
	.input-group-append {
		border-radius: if-ltr(0 1.3rem 1.3rem 0, 1.3rem 0 0 1.3rem);
		border: 1px solid #EEE;
		border-#{$left}: 0;
		.btn {
			background: transparent !important;
			border: 0;
			box-shadow: none !important;
		}
	}
}

/* Search With Select */
.search-with-select {
	.mobile-search-toggle-btn {
		display: none;
	}
	.search-form-wrapper {
		> .form-control {
			background-color: #f4f4f4;
			border-radius: if-ltr(1.3rem 0 0 1.3rem, 0 1.3rem 1.3rem 0 !important);
			border: 0;
			height: auto;
		    padding: 0.6rem 1.4rem;
		    font-size: 0.8rem !important;
		    color: #8d8d8d;
		    @include placeholder-color(#8d8d8d);
		    &:focus {
				box-shadow: none;
			}
		}
		.input-group-append {
			background-color: #f4f4f4;
			border-radius: if-ltr(0 1.3rem 1.3rem 0, 1.3rem 0 0 1.3rem);
			.custom-select-1 {
				&:before {
					color: #8d8d8d;
				}
				select {
					background-color: transparent;
					border: 1px solid #FFF;
					border-top-width: 0;
					border-bottom-width: 0;
				    border-radius: 0;
				    color: #8d8d8d;
				    height: 100%;
					z-index: 1;
					&:focus {
						box-shadow: none;
					}
				}
			}
			.btn {
				background: transparent !important;
				border: 0;
				box-shadow: none !important;
				.header-nav-top-icon {
				    font-size: 1rem;
				    top: 2px;
				    right: 2px;
				    color: $color-dark;
				    font-weight: bold;
				}
			}
		}
	}
}

@media(max-width: 991px) {
	.search-with-select {
		position: relative;
		float: right;
		.mobile-search-toggle-btn {
			display: block;
			text-decoration: none;
			&.open {
				& + .search-form-wrapper {
					display: flex;
					visibility: visible;
					opacity: 1;
				}
			}
			> i {
			    position: relative;
			    top: 2px;
			    font-size: 1.9rem;
			}
		}
		.search-form-wrapper {
			display: none;
			visibility: hidden;
			opacity: 0;
			position: absolute;
		    top: 36px;
		    left: 50%;
		    transform: translate3d(-50%, 0, 0);
		    width: 300px;
		    background: #d8d8d8;
		    padding: 0.2rem;
		    border-radius: 28px;
		    transition: ease opacity 300ms;
		    &:before {
		    	content: '';
		    	position: absolute;
		    	bottom: 100%;
		    	left: 50%;
		    	border-bottom: 6px solid #d8d8d8;
		    	border-left: 6px solid transparent;
		    	border-right: 6px solid transparent;
		    	transform: translate3d(-50%, 0, 0);
		    }
		}
	}
}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
	input {
		border: 0;
	}
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: $left;
	position: relative;
	top: -5px;
	#{$left}: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
	a, a:hover, a:focus, a:active {
		color: #333;
	}
}

/* Form Styles */
.form-style-2 {
	.form-group {
		margin-bottom: 0.7rem;
	}
	.form-control {
	    padding: 0.7rem 1rem;
	    border: 0;
	    &::-webkit-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&::-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-ms-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}

		&:not(textarea) {
			height: calc(2.2em + .75rem + 2px);
		}

		&.error {
			border-width: 2px;
		}
	}
}

.form-style-3 {
	.form-group {
		margin-bottom: 1.1rem;
	}
	.form-control {
	    padding: 0.7rem 1rem;
	    background: #F4F4F4;
	    border: none;
		&::-webkit-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&::-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-ms-input-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}
		&:-moz-placeholder {
		  color: #202528;
		  opacity: 0.5;
		}

		&.error {
		    border: 2px solid #e21a1a;
		}
	}
}

.form-style-4 {
	.form-control {
		background: transparent;
	    border-top: 0;
	    border-right: 0;
	    border-left: 0;
	    border-bottom-color: rgba(0,0,0,0.4);
	    color: #FFF;
	    box-shadow: none !important;
	    border-radius: 0;

	    &::-webkit-input-placeholder {
		  	color: #d8d8d8;
		}
		&::-moz-placeholder {
		  	color: #d8d8d8;
		}
		&:-ms-input-placeholder {
		  	color: #d8d8d8;
		}
		&::-ms-input-placeholder {
		  	color: #d8d8d8;
		}
		&::placeholder {
		  	color: #d8d8d8;
		}
	}

	select.form-control {
		color: #FFF;
		option {
			color: #777;
		}
	}

	.form-check-label {
		color: #d8d8d8;
	}

	&.form-style-4-text-dark {
		.form-control {
			color: #212121;

			&::-webkit-input-placeholder {
			  	color: #777;
			}
			&::-moz-placeholder {
			  	color: #777;
			}
			&:-ms-input-placeholder {
			  	color: #777;
			}
			&::-ms-input-placeholder {
			  	color: #777;
			}
			&::placeholder {
			  	color: #777;
			}
		}

		.form-check-label {
			color: #777;
		}
	}

	&.form-style-4-border-light {
		.form-control {
			border-color: rgba(255, 255, 255, 0.4);
		}
	}

	&.form-with-icons {
		.form-control {
			padding-left: 31px;
		}
	}
}

.form-style-5 {
	.form-control {
		background: rgba(255,255,255,0.05);
	    border-radius: 35px;
	    height: auto;
	    padding: 0.5rem 1.5rem;
	    color: #bfbfbf;
	    &:focus {
	    	border-color: #676767;
	    }
	}
}

/* Floating Labels */
.form-label-group {
  	position: relative;
  	margin-bottom: 1rem;
  	input, label {
  		height: 3.125rem;
		padding: .75rem;
  	}
  	label {
  		position: absolute;
	  	top: 0;
	  	left: 0;
	  	display: block;
	  	width: 100%;
	  	margin-bottom: 0;
	  	line-height: 1.5;
	  	color: #495057;
	  	pointer-events: none;
	  	cursor: text;
	  	border: 1px solid transparent;
	  	border-radius: .25rem;
	  	transition: all .1s ease-in-out;
  	}
  	input {
  		&::-webkit-input-placeholder {
		  	color: transparent;
		}
		&::-moz-placeholder {
		  	color: transparent;
		}
		&:-ms-input-placeholder {
		  	color: transparent;
		}
		&::-ms-input-placeholder {
		  	color: transparent;
		}
		&::placeholder {
		  	color: transparent;
		}

		&:not(:-moz-placeholder-shown) {
		  	padding-top: 1.25rem;
		  	padding-bottom: .25rem;
		}

		&:not(:-ms-input-placeholder) {
		  	padding-top: 1.25rem;
		  	padding-bottom: .25rem;
		}

		&:not(:placeholder-shown) {
		  	padding-top: 1.25rem;
		  	padding-bottom: .25rem;
		}

		&:not(:-moz-placeholder-shown) ~ label {
		  	padding-top: .25rem;
		  	padding-bottom: .25rem;
		  	font-size: 12px;
		  	color: #777;
		}

		&:not(:-ms-input-placeholder) ~ label {
		  	padding-top: .25rem;
		  	padding-bottom: .25rem;
		  	font-size: 12px;
		  	color: #777;
		}

		&:not(:placeholder-shown) ~ label {
		 	padding-top: .25rem;
		  	padding-bottom: .25rem;
		  	font-size: 12px;
		  	color: #777;
		}
  	}
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  	.form-label-group {
  		display: -ms-flexbox;
    	display: flex;
    	-ms-flex-direction: column-reverse;
    	flex-direction: column-reverse;
  	}

  	.form-label-group label {
    	position: static;
  	}

  	.form-label-group input::-ms-input-placeholder {
    	color: #777;
  	}
}

/* Form Squared Borders */
.form-squared-borders {
	.form-control {
		border-radius: 0;
	}
}

/* Form With Icons */
.form-with-icons {
	.form-control {
		padding-left: 41px;
	}
}

/* Form Fields Rounded */
.form-fields-rounded {
	.form-control {
	    padding-left: 18px;
	    padding-right: 18px;
		border-radius: 35px;
	}
	textarea.form-control {
		border-radius: 22px;
	}
}

/* Form With Shadow */
.form-with-shadow {
	.form-control {
		box-shadow: 0px 0px 17px -4px rgba(0, 0, 0, 0.1) !important;
	}
}