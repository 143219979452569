/* Video */
section.video {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
	.container {
		position: relative;
		z-index: 3;
	}
}

html.boxed {
	section.video {
		> div:first-child {
			z-index: 0 !important;
			video {
				z-index: 0 !important;
			}
		}
	}
}