@import './assets/vendor/simple-line-icons/scss/simple-line-icons.scss';
@import './assets/vendor/fontawesome-free/css/all.min.css';
@import './assets/styles/sass/variable-custom';
@import './assets/vendor/bootstrap/scss/bootstrap.scss';
@import './assets/vendor/animate/animate.compat.css';
@import './assets/styles/sass/theme.scss';
@import './assets/styles/sass/theme-elements.scss';
@import './assets/styles/sass/theme-blog.scss';
@import './assets/styles/sass/theme-shop.scss';
@import './assets/styles/sass/skin.css';
@import './assets/styles/sass/custom.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~ngx-toastr/toastr-bs4-alert';
html[dir="rtl"] {
    direction: rtl;
    * {
        direction: rtl;
    }
    @import './assets/styles/rtl.scss';
}

body {
    cursor: url('./assets/img/rsaam-imgs/pen.png'), auto;
}