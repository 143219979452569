/* Timeline */
section.timeline {
	width: 100%;
	margin: 50px 0 0;
	position: relative;
	float: $left;
	padding-bottom: 120px;
	&:before {
		background: rgb(80,80,80);
		background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		content: "";
		display: block;
		height: 100%;
		#{$left}: 50%;
		margin-#{$left}: -2px;
		position: absolute;
		top: -60px;
		width: 3px;
		z-index: 0;
		filter: alpha(opacity = 35);
		opacity: 0.35;
	}
	.timeline-body {
		position: relative;
		z-index: 1 !important;
	}
	.timeline-date {
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
		position: relative;
		display: block;
		clear: both;
		width: 200px;
		height: 45px;
		padding: 5px;
		border: 1px solid #E5E5E5;
		margin: 0 auto 10px;
		text-shadow: 0 1px 1px #fff;
		border-radius: 4px;
		background: #FFF;
		z-index: 1;
		h3 {
			display: block;
			text-align: center;
			color: #757575;
			font-size: 0.9em;
			line-height: 32px;
		}
	}
	.timeline-title {
		background: #f7f7f7;
		padding: 12px;
		h4 {
			padding: 0;
			margin: 0;
			color: #151719;
			font-size: 1.4em;
		}
		a {
			color: #151719;
		}
		.timeline-title-tags {
			color: #B1B1B1;
			font-size: 0.9em;
			a {
				color: #B1B1B1;
				font-size: 0.9em;
			}
		}
	}
	.timeline-box {
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
		border: 1px solid #E5E5E5;
		border-radius: 4px;
		margin: 20px 10px 10px 10px;
		padding: 10px;
		width: 45.7%;
		float: $left;
		z-index: 1;
		margin-bottom: 30px;
		background: #FFF;
		&.left {
			clear: both;
			&:before {
				background: none repeat scroll 0 0 #E5E5E5;
				border-radius: 50%;
				box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
				content: "";
				display: block;
				height: 7px;
				margin-#{$right}: -5px;
				position: absolute;
				left: 50%;
				margin-top: 22px;
				width: 8px;
				transform: translate3d(-50%, 0, 0);
				z-index: 1;
			}
			.timeline-box-arrow {
				@include if-ltr {
					transform: rotate(45deg);
				}
				@include if-rtl {
					transform: rotate(-45deg);
				}
				content: '';
				display: block;
			    background: #FFF;
			    border-right: 1px solid #E5E5E5;
			    border-top: 1px solid #E5E5E5;
			    float: $right;
			    height: 14px;
			    margin-top: 17px;
			    margin-#{$right}: -18px;
			    width: 14px;
			    z-index: 0;
			}
		}
		&.right {
			clear: $right;
			float: $right;
			#{$right}: -1px;
			margin-top: 40px;
			&:before {
				background: none repeat scroll 0 0 #E5E5E5;
				border-radius: 50%;
				box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
				content: "";
				display: block;
				height: 7px;
				position: absolute;
				left: 50%;
				margin-top: 32px;
				width: 8px;
				transform: translate3d(-50%, 0, 0);
				z-index: 1;
			}
			.timeline-box-arrow {
				@include if-ltr {
					transform: rotate(45deg);
				}
				@include if-rtl {
					transform: rotate(-45deg);
				}
				content: '';
				display: block;
			    background: #FFF;
			    border-left: 1px solid #E5E5E5;
			    border-bottom: 1px solid #E5E5E5;
			    float: $left;
			    height: 14px;
			    margin-top: 28px;
			    margin-#{$left}: -18px;
			    width: 14px;
			    z-index: 0;
			}
		}
		.owl-carousel {
			border-radius: 0;
			padding: 0;
			border: 0;
			box-shadow: none;
		}
	}
}

@media (max-width: 991px) {

	section.timeline .timeline-box {
		float: none;
		clear: both;
		#{$right}: auto;
		#{$left}: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
		&.right {
			float: none;
			clear: both;
			#{$right}: auto;
			#{$left}: auto;
			margin: 25px auto;
			max-width: 70%;
			width: auto;
		}
		&.left:after, &.right:after, &.left:before, &.right:before {
			display: none;
		}
	}	

}

@media (max-width: 767px) {

	section.timeline {
		width: 100%;
		margin: 0;
	}

}

@media (max-width: 575px) {

	section.timeline {
		width: auto;
		float: none;
		margin-#{$left}: 0;
		margin-#{$right}: 0;
		.timeline-box {
			margin: 15px auto;
			max-width: 90%;
			&.right {
				margin: 15px auto;
				max-width: 90%;
			}
		}
	}	

	article.post-large {
		.post-image, .post-date {
			margin-#{$left}: 0;
		}
		margin-#{$left}: 0;
	}

}