/* Nav */
.nav {
	@include if-rtl() {
		padding-right: 0;
	}
	> li {
		> a:hover, > a:focus {
			background: transparent;
		}
	}
}

/* Nav List */
ul {
	&.nav-list {
		li {
			margin: 0;
			padding: 0;
			display: block;
			position: relative;
			a {
				transition: background 0.1s;
				border-bottom: 1px solid rgba(0,0,0,0.06);
				padding: side-values(8px 0 8px 22px);
				display: block;
				color: #666;
				font-size: 0.9em;
				text-decoration: none;
				line-height: 20px;
				&:before {
					font-family: 'Font Awesome 5 Free';
					content: "\f054";
					font-weight: 900;
					position: absolute;
					top: 8px;
					font-size: 0.45rem;
					opacity: 0.7;
					transform: translate3d(-1px, 0, 0);
					#{$left}: 12px;
					@include if-rtl {
						content: "\f053";
					}
				}
				&:hover {
					&:before,
					i[class*="fa-chevron"] {
						animation-name: arrowLeftRight;
						animation-duration: 500ms;
						animation-iteration-count: infinite;
					}
					text-decoration: none;
				}

				i[class*="fa-chevron"] {
					position: relative;
					top: -1px;
				    font-size: 0.5rem;
				    opacity: 0.8;
				}
			}
			> a {
				&.active {
					font-weight: bold;
				}
			}
			ul {
				list-style: none;
				margin: side-values(0 0 0 25px);
				padding: 0;
			}
			&:last-child {
				a {
					border-bottom: 0;
				}
			}
		}

		&.hide-not-active {
			li {
				ul {
					display: none;
				}
				&.active {
					ul {
						display: block;
					}
				}
			}
		}

		&.show-bg-active {
			.active > a,
			a.active {
				background-color: #f5f5f5;
				&:hover {
					background-color: #eee;
				}
			}
		}

		&.no-arrows {
			li {
				a {
					&:before {
						content: none;
					}
				}
			}
		}
	}

	&.nav-list-simple {
		li {
			margin: 0;
			padding: 0;
			display: block;
			position: relative;
			a {
				transition: background 0.1s;
				border-bottom: 1px solid rgba(0,0,0,0.07);
				padding: 8px 0;
				display: block;
				color: #666;
				font-size: 0.9em;
				text-decoration: none;
				line-height: 20px;
				transition: ease padding 300ms;
			}
			> a {
				&.active {
					font-weight: bold;
				}
			}
			&:hover {
				a {
					padding-left: 4px;
				}
			}
			ul {
				list-style: none;
				margin: side-values(0 0 0 25px);
				padding: 0;
			}
		}
	}

	&.narrow {
		li {
			a {
				padding: 4px 0;
			}
		}
	}

	&.nav-pills > li > a.active {
		color: #FFF;
		background-color: #CCC;
		&:hover, &:focus {
			color: #FFF;
			background-color: #CCC;
		}
	}
}

/* Nav Pills */
.nav-pills-center {
	text-align: center;
	> li {
		display: inline-block;
		float: none;
	}
}

.nav-pills-sm {
	> li > a {
		padding: 7px 12px;
	}
}

.nav-pills-xs {
	> li > a {
		padding: 5px 10px;
		font-size: 0.9em;
	}
}

.nav-pills {
	.nav-link {
		&.active {
			background-color: transparent;
		}
	}
}

.show {
	> .nav-pills {
		.nav-link {
			background-color: transparent;
		}	
	}
}

/* Nav Light */
.nav-light {
	> li {
		> a {
			color: #FFF;
		}
	}
}

/* Nav Active Style - Active Bottom Border */
.nav-active-style-1 {
	min-height: 100px;
	align-items: center;
	> li {
		align-self: stretch;
		> a {
		    display: flex;
		    align-items: center;
			height: 100%;
			padding: 0 !important;
			margin: 0 15px;
			border-bottom: 2px solid transparent;
			transition: ease border-color 300ms;
			&:hover,
			&:focus,
			&.active {
				border-bottom-color: #CCC;
			}
		}
	}
}

@media(max-width: 575px) {
	.nav-active-style-1 {
		> li {
			> a {
				min-height: 45px;
				justify-content: center;
			}
		}
	}
}

html.ie {
	.nav-active-style-1 {
		> li {
			min-height: 100px;
		}
	}
}

/* Nav Secondary */
.nav-secondary {
	background: #fafafa;
	margin: 0;
	padding: 20px 0;
	text-align: center;
	z-index: 99;
	width: 100% !important;
	.nav-pills > li {
		float: none;
		display: inline-block;
		a {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			padding: 10px 13px;
			text-transform: uppercase;
		}
	}
	&.sticky-active {
		border-bottom-color: #e9e9e9;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	}
}

/* Boxed */
html.boxed {
	.nav-secondary {
		&.sticky-active {
			max-width: 1200px;
			left: auto !important;
		}
	}
}

/* Section Scroll - Dots Navigation */
.section-scroll-dots-navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: 30px;
    background: #FFF;
    border-radius: 35px;
    padding: 0.6rem;
    z-index: 10;
    transform: translate3d(0, -50%, 0);

    > ul {
	    margin: 0.5rem 0;
	    &:hover {
    		> li {
    			&.active {
					&:hover {
						> a {
							> span {
		    					opacity: 1;
		    					visibility: visible;
		    				}	
						}
					}					
					> a {
						> span {
	    					opacity: 0;
	    					visibility: hidden;
	    				}
					}
				}
    		}
    	}
    	> li {
    		position: relative;
    		margin-bottom: 0.3rem;
    		&:last-child {
    			margin-bottom: 0;
    		}

			&:hover {
				background: transparent;
				> a {
					> span {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&.active {
				> a {
					&:before {
						opacity: 1;
					}
				}
			}

    		> a {
    			position: relative;
			    width: 15px;
			    height: 15px;
			    display: block;
			    padding: 0.3rem;
			    &:before {
			    	content: '';
			    	position: absolute;
			    	top: 50%;
			    	left: 50%;
			    	transform: translate3d(-50%, -50%, 0);
			    	width: 8px;
			    	height: 8px;
			    	border-radius: 100%;
			    	opacity: 0.3;
			    	background: #CCC;
			    	transition: ease opacity 300ms;
			    }
			    > span {
    				position: absolute;
					top: 50%;
				    right: calc(100% + 20px);
					background: #FFF;
					color: $color-dark;
				    font-size: 0.8rem;
				    font-weight: 800;
				    padding: 0.2rem 1rem;
				    border-radius: 4px;
				    white-space: nowrap;
				    opacity: 0;
				    visibility: hidden;
				    transform: translate3d(0, -50%, 0);
				    transition: ease opacity 300ms;
				    &:after {
				    	content: '';
				    	position: absolute;
				    	top: 50%;
				    	left: 100%;
				    	width: 0; 
						height: 0; 
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 6px solid #F7F7F7;
						transform: translate3d(0, -50%, 0);
				    }
    			}
		    }
		}
    }

    &.section-scroll-dots-navigation-style-2 {
    	background: transparent;
    }

    &.section-scroll-dots-navigation-light {
		> ul {
			> li {
	    		> a {
				    &:before {
				    	background: #FFF;
				    }
				    > span {
					    right: calc(100% + 10px);
	    			}
			    }
			}
	    }
    }
}

@media(max-width: 767px) {
	.section-scroll-dots-navigation {
		right: 15px;
	}
}