/* GDPR */
.gdpr-bar {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: rgba(0, 0, 0, 0.9);
    padding: 1rem 1.5rem;
	z-index: 1050;
	opacity: 0;
	visibility: hidden;
	transform: translate3d(0, 100%, 0);
	transition: ease opacity 300ms, ease transform 300ms;
	.gdpr-bar-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 15px;
		.gdpr-agree-trigger {
			margin-#{$left}: 2rem;
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
	&.removing {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

@media(min-width: 1200px) {
	.gdpr-bar {
		flex-direction: row;
		align-items: center;
		.gdpr-bar-actions {
			justify-content: flex-end;
			margin-top: 0;
		}
	}
}

.gdpr-preferences-popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1051;
	opacity: 0;
	visibility: hidden;
	transition: ease opacity 300ms;
	.gdpr-preferences-popup-content {
		max-width: 1000px;
		background: #FFF; 
		.gdpr-close-popup {
			position: absolute;
			top: 25px;
			right: 25px;
		}
		.gdpr-preferences-popup-content-body {
			max-height: 60vh;
			overflow-y: auto;
			padding: 2.5rem;
			.gdpr-cookie-item {
				display: flex;
				flex-wrap: wrap;
				font-size: 0.8rem;
				.gdpr-cookie-item-title {
					width: 100%;
				}
				.gdpr-cookie-item-description {
					width: 70%;
				}
				.gdpr-cookie-item-action {
					width: 30%;
					text-align: right;
				}

				& + .gdpr-cookie-item {
					margin-top: 10px;
				}
			}
		}
		.gdpr-preferences-popup-content-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.5rem 2.5rem;
			background: #f4f4f4;
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

@media(min-width: 992px) {
	.gdpr-preferences-popup {
		.gdpr-preferences-popup-content {
			.gdpr-preferences-popup-content-body {
				.gdpr-cookie-item {
					flex-wrap: nowrap;
					.gdpr-cookie-item-title {
						width: 20%;
					}
					.gdpr-cookie-item-description {
						width: 60%;
					}
					.gdpr-cookie-item-action {
						width: 20%;
					}
				}
			}
		}
	}
}