/* Custom Map */

section.section-custom-map {
    background: transparent url(../../../img/map.png) center 0 no-repeat;
    padding: 129px 0 0;
    margin: $spacement-lg 0 0;
    border: 0;
    section.section {
        border-top: 0;
        background: rgba(#f7f7f7, 0.5);
    }
}


/* Home Intro */

.home-intro {
    background-color: #212529;
    margin-bottom: 60px;
    overflow: hidden;
    padding: 2.4rem 0;
    position: relative;
    text-align: $left;
    letter-spacing: -0.5px;
    p {
        color: #FFF;
        display: inline-block;
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 0;
        span:not(.highlighted-word) {
            color: #FFF;
            display: block;
            font-size: 0.8em;
            font-weight: 300;
            padding-top: 5px;
            opacity: 0.7;
        }
        .highlighted-word {
            font-size: 1.6em;
        }
    }
    .get-started {
        position: relative;
        .btn {
            position: relative;
            z-index: 1;
            i {
                font-size: 1.1em;
                font-weight: normal;
                margin-#{$left}: 5px;
            }
        }
        a {
            color: #FFF;
        }
        a:not(.btn) {
            color: #FFF;
            font-weight: 600;
        }
    }
    .learn-more {
        color: #FFF;
        margin-#{$left}: 15px;
        font-weight: 300;
    }
    &.light {
        background-color: #EDEDED;
        &.secondary {
            background-color: #E0E1E4;
        }
        p,
        .learn-more,
        p span:not(.highlighted-word),
        .get-started a:not(.btn) {
            color: #333333;
        }
    }
}


/* Home Intro Compact */

.home-intro-compact {
    border-radius: corner-values(0 0 .3rem .3rem);
    margin-top: -10px;
    margin: -10px auto 60px;
    max-width: 1110px;
    p {
        padding-#{$left}: 20px;
    }
    .get-started {
        padding-#{$right}: 20px;
    }
}


/* Responsive */

@media (max-width: 991px) {
    /* Home Intro */
    .home-intro {
        p {
            margin-bottom: 2rem;
        }
    }
    .home-intro-compact {
        .get-started {
            padding-#{$left}: 20px;
        }
    }
}

@media (max-width: 767px) {
    /* Home Intro */
    .home-intro-compact {
        margin-#{$left}: 0;
        margin-#{$right}: 0;
        padding-#{$left}: 0;
        padding-#{$right}: 0;
    }
}


/* Home Concept SVG Animations */

@keyframes animatedLineBg {
    from {
        stroke-dasharray: 1000;
    }
    to {
        stroke-dasharray: 1360;
    }
}

@keyframes animatedLineSmallCircle {
    from {
        stroke-dasharray: 1000;
    }
    to {
        stroke-dasharray: 1120;
    }
}

@keyframes animatedLineBigCircle {
    from {
        stroke-dasharray: 1000;
    }
    to {
        stroke-dasharray: 1266;
    }
}

@keyframes animatedInitialPin {
    0% {
        transform: translate(2px, 69px) scale(0.23);
    }
    33% {
        transform: translate(2px, 79px) scale(0.23);
    }
    66% {
        transform: translate(2px, 74px) scale(0.23);
    }
    100% {
        transform: translate(2px, 79px) scale(0.23);
    }
}

@keyframes animatedFinalPin {
    0% {
        transform: translate(325px, -19px) scale(0.23) rotate(36deg);
    }
    33% {
        transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    }
    66% {
        transform: translate(325px, -16px) scale(0.23) rotate(36deg);
    }
    100% {
        transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    }
}


/* Firefox Final Pin Animation */

@keyframes animatedFinalPinFirefox {
    0% {
        transform: translate(193px, -16px) scale(0.23) rotate(36deg);
    }
    33% {
        transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    }
    66% {
        transform: translate(193px, -13px) scale(0.23) rotate(36deg);
    }
    100% {
        transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    }
}


/* Home Concept */

.home-concept {
    background: if-ltr(transparent url(../../../img/home/home-concept.png) no-repeat center 0, transparent url(../../../img/home/home-concept-rtl.png) no-repeat center 0);
    width: 100%;
    overflow: hidden;
    strong {
        display: block;
        font-size: 1.8em;
        font-weight: normal !important;
        position: relative;
        margin-top: 30px;
    }
    .row {
        position: relative;
    }
    .process-image {
        background: transparent url(../../../img/home/home-concept-item.png) no-repeat 0 0;
        width: 160px;
        margin: 120px auto 0 auto;
        padding-bottom: 50px;
        position: relative;
        z-index: 1;
        img {
            border-radius: 150px;
            margin: 7px 8px;
            width: auto;
            height: auto;
            max-width: 145px;
            max-height: 145px;
        }
        @media(min-width: 992px) {
            &.process-image-on-middle {
                margin-top: 95px;
            }
        }
    }
    .our-work {
        margin-top: 52px;
        font-size: 2.2em;
    }
    .project-image {
        background: transparent url(../../../img/home/home-concept-item.png) no-repeat 100% 0;
        width: 350px;
        margin: side-values(20px 0 0 -30px);
        padding-bottom: 45px;
        position: relative;
        z-index: 1;
    }
    .sun {
        background: transparent url(../../../img/home/home-concept-icons.png) no-repeat 0 0;
        width: 60px;
        height: 56px;
        display: block;
        position: absolute;
        #{$left}: 0;
        top: 65px;
    }
    .cloud {
        background: transparent url(../../../img/home/home-concept-icons.png) no-repeat 100% 0;
        width: 116px;
        height: 56px;
        display: block;
        position: absolute;
        #{$left}: 53%;
        top: 35px;
    }
}


/* Responsive */

@media (max-width: 991px) {
    .home-concept {
        background-image: none;
        .project-image {
            margin: 20px auto 0 auto;
        }
        .process-image {
            margin-top: 0;
            padding-bottom: 25px;
        }
    }
}

@media (max-width: 479px) {
    .home-concept {
        .project-image {
            background-position: 0 0;
            margin-#{$left}: auto;
            max-width: 160px;
        }
        .fc-slideshow {
            max-width: 145px;
            height: 145px;
            img {
                max-height: 145px;
                max-width: 145px;
            }
        }
    }
}