/* Text */
.text-color-grey {
	color: #969696 !important;
}

.text-color-grey-lighten {
	color: #a9a9aa !important;
}

.text-color-white {
	color: #FFF !important;
}

.text-color-hover-white:hover {
	color: #FFF !important;
}

.text-color-black {
	color: #000 !important;
}

.text-color-hover-black:hover {
	color: #000 !important;
}

.text-color-danger {
	color: #ff5b5b !important;
}

.text-color-success {
	color: #0cc485 !important;
}

/* Grey */
.bg-color-grey {
	background-color: #F7F7F7 !important;
}

.bg-color-grey-scale-1 {
	background-color: #EDEDED !important;
}

.bg-color-grey-scale-2 {
	background-color: darken(#EDEDED, 2%) !important;
}

.bg-color-grey-scale-3 {
	background-color: darken(#EDEDED, 4%) !important;
}

.bg-color-grey-scale-4 {
	background-color: darken(#EDEDED, 6%) !important;
}

.bg-color-grey-scale-5 {
	background-color: darken(#EDEDED, 8%) !important;
}

.bg-color-grey-scale-6 {
	background-color: darken(#EDEDED, 10%) !important;
}

.bg-color-grey-scale-7 {
	background-color: darken(#EDEDED, 12%) !important;
}

.bg-color-grey-scale-8 {
	background-color: darken(#EDEDED, 14%) !important;
}

.bg-color-grey-scale-9 {
	background-color: darken(#EDEDED, 16%) !important;
}

.bg-color-grey-scale-10 {
	background-color: darken(#EDEDED, 18%) !important;
}

/* Dark */
.bg-color-dark-scale-1 {
	background-color: $color-dark !important;
}

.bg-color-dark-scale-2 {
	background-color: darken($color-dark, 2%) !important;
}

.bg-color-dark-scale-3 {
	background-color: darken($color-dark, 4%) !important;
}

.bg-color-dark-scale-4 {
	background-color: darken($color-dark, 6%) !important;
}

.bg-color-dark-scale-5 {
	background-color: darken($color-dark, 8%) !important;
}

/* Borders */
.border-color-light-2 {
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.border-color-light-3 {
    border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-color-light-4 {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-color-grey-1 {
	border-color: #e9e9e9 !important;
}

.border-color-success {
	border-color: #0cc485 !important;
}

/* SVG */
.svg-fill-color-light {
	svg {
		path, rect {
			fill: $color-light !important;
		}
	}
}

.svg-stroke-color-light {
	svg {
		path, rect {
			stroke: $color-light !important;
		}
	}
}

.svg-fill-color-dark {
	svg {
		path, rect {
			fill: $color-dark !important;
		}
	}
}

.svg-stroke-color-dark {
	svg {
		path, rect {
			stroke: $color-dark !important;
		}
	}
}

/* Star Rating */
.rating-default {
	.filled-stars {
		color: #6a6a6d;
	}
}