/* Page Header */
.page-header {
	background-color: $color-dark;
	margin: 0 0 35px 0;
	padding: 30px 0;
	position: relative;
	text-align: $left;

	h1 {
		color: #fff;
		display: inline-block;
		font-size: 30px;
		line-height: 1;
		margin: 0;
		padding: 0;
		font-weight: 400;
		position: relative;
		top: 1px;
	}

	.sub-title {
		display: block;
		font-size: 1.2em;
		font-weight: 300;
		margin: 0;
		opacity: 0.8;
		color: #fff;
	}

	// Extra Button
	.page-header-extra-button {
		position: absolute;
		width: 100%;
		bottom: 50px;
		left: 0;
		right: 0;
		z-index: 5;
	}

	// Page Header Small
	&.page-header-sm {
		padding: 30px 0;
		h1 {
			font-size: 22px;
		}
	}

	// Page Header Large
	&.page-header-lg {
		padding: 50px 0;
		h1 {
			font-size: 40px;
		}
	}

	// Classic
	&.page-header-classic {
		&:after {
			content: '';
			width: 100%;
			height: 5px;
			background: rgba(255,255,255,0.8);
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.page-header-title-border {
			width: 0;
			height: 5px;
			position: absolute;
			bottom: 0;
			background: transparent;
			z-index: 1;
		}
	}

	// Modern
	&.page-header-modern {
		h1 {
			font-size: 28px;
		}
		&.page-header-sm {
			h1 {
				font-size: 22px;
			}
		}
		&.page-header-md {
			padding: 50px 0;
		}
		&.page-header-lg {
			padding: 65px 0;
			h1 {
				font-size: 32px;
			}
		}

		// background
		&.page-header-background {
			padding: 130px 0;
			background-size: cover;

			&.page-header-background-pattern {
				background-size: auto;
				background-repeat: repeat;
				background-attachment: fixed;
			}

 			&.page-header-background-sm {
 				padding: 50px 0;
 			}

 			&.page-header-background-md {
 				padding: 150px 0;
 			}

 			&.page-header-background-lg {
 				padding: 250px 0;
 			}

		}

	}
}