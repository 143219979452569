/* Sidebar */
aside {
	ul.nav-list > li > a {
		color: #666;
		font-size: 0.9em;
		padding: side-values(8px 0 8px 22px);
	}

	// Twitter
	.twitter {
		[class*="fa-"], .icons {
			clear: both;
			font-size: 1.5em;
			position: relative;
			top: 3px;
			margin-#{$right}: -22px;
			#{$left}: -30px;
			color: $color-dark;
		}
		.meta {
			display: block;
			font-size: 0.9em;
			padding-top: 3px;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				padding-bottom: 20px;
				padding-#{$left}: 30px;
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	.twitter-account {
		display: block;
		font-size: 0.9em;
		margin: -15px 0 5px;
		opacity: 0.55;
		&:hover {
			opacity: 1;
		}
	}
}