/* Parallax */
.parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	position: relative;
	clear: both;

	&:not(.page-header):not(.match-height):not([class*="section-height-"]) {
		margin: 70px 0;
		padding: 50px 0;
	}

	blockquote {
		border: 0;
		padding: 0 10%;
		i.fa-quote-left {
			font-size: 34px;
			position: relative;
			#{$left}: -5px;
		}
		p {
			font-size: 24px;
			line-height: 30px;
		}
		span {
			font-size: 16px;
			line-height: 20px;
			color: #999;
			position: relative;
			#{$left}: -5px;
		}
	}
}

.parallax-disabled {
	.parallax-background {
		background-attachment: scroll !important;
		background-position: 50% 50% !important;
	}
}