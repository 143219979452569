/* Arrows */

.arrow {
    background: transparent url(../../../img/arrows.png) no-repeat 0 0;
    width: 47px;
    height: 120px;
    display: inline-block;
    position: relative;
    &.arrow-light {
        background-image: url(../../../img/arrows-dark.png);
    }
    &.vtl {
        @include if-ltr {
            background-position: 0 0;
        }
        @include if-rtl {
            background-position: -101px 0;
        }
        width: 47px;
        height: 96px;
    }
    &.vtr {
        @include if-ltr {
            background-position: -101px 0;
        }
        @include if-rtl {
            background-position: 0 0;
        }
        width: 47px;
        height: 96px;
    }
    &.vbl {
        @include if-ltr {
            background-position: 0 -144px;
        }
        @include if-rtl {
            background-position: -101px -144px;
        }
        width: 47px;
        height: 96px;
    }
    &.vbr {
        @include if-ltr {
            background-position: -101px -144px;
        }
        @include if-rtl {
            background-position: 0 -144px;
        }
        width: 47px;
        height: 96px;
    }
    &.hlt {
        @include if-ltr {
            background-position: -209px 0;
        }
        @include if-rtl {
            background-position: -353px 0;
        }
        width: 120px;
        height: 47px;
    }
    &.hlb {
        @include if-ltr {
            background-position: -209px -101px;
        }
        @include if-rtl {
            background-position: -353px -101px;
        }
        width: 120px;
        height: 47px;
    }
    &.hrt {
        @include if-ltr {
            background-position: -353px 0;
        }
        @include if-rtl {
            background-position: -209px 0;
        }
        width: 120px;
        height: 47px;
    }
    &.hrb {
        @include if-ltr {
            background-position: -353px -101px;
        }
        @include if-rtl {
            background-position: -209px -101px;
        }
        width: 120px;
        height: 47px;
    }
}


/* Responsive */

@media (max-width: 575px) {
    .arrow {
        display: none;
    }
}