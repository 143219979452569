/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
	li {
		display: inline-block;
		margin: side-values(-1px 1px 0 0);
		padding: 0;
		border-radius: 100%;
		overflow: visible;
		a {
			transition: all 0.2s ease;
			border-radius: 100%;
			display: block;
			height: 28px;
			line-height: 28px;
			width: 28px;
			text-align: center;
			color: #333 !important;
			text-decoration: none;
			font-size: 0.8rem;
		}
		&:hover {
			a {
				background: #151719;
				color: #FFF !important;
			}
			&.social-icons-twitter {
				a {
					background: #1aa9e1 !important;
				}
			}
			&.social-icons-facebook {
				a {
					background: #3b5a9a !important;
				}
			}
			&.social-icons-linkedin {
				a {
					background: #0073b2 !important;
				}
			}
			&.social-icons-rss {
				a {
					background: #ff8201 !important;
				}
			}
			&.social-icons-googleplus {
				a {
					background: #dd4b39 !important;
				}
			}
			&.social-icons-pinterest {
				a {
					background: #cc2127 !important;
				}
			}
			&.social-icons-youtube {
				a {
					background: #c3191e !important;
				}
			}
			&.social-icons-instagram {
				a {
					background: #7c4a3a !important;
				}
			}
			&.social-icons-skype {
				a {
					background: #00b0f6 !important;
				}
			}
			&.social-icons-email {
				a {
					background: #dd4b39 !important;
				}
			}
			&.social-icons-vk {
				a {
					background: #6383a8 !important;
				}
			}
			&.social-icons-xing {
				a {
					background: #1a7576 !important;
				}
			}
			&.social-icons-tumblr {
				a {
					background: #304e6c !important;
				}
			}
			&.social-icons-reddit {
				a {
					background: #ff4107 !important;
				}
			}
			&.social-icons-whatsapp {
				a {
					background: #25d366 !important;
				}
			}
		}
	}
	&.social-icons-dark {
		li {
			a {
				background: #181818;
				i {
					color: #FFF;
				}
			}
		}
	}
	&.social-icons-dark-2 {
		li {
			a {
				background: #272a2e;
				i {
					color: #FFF;
				}
			}
		}
	}
	&.social-icons-transparent {
		li {
			box-shadow: none;
			a {
				background: transparent;
			}
		}
	}
	&.social-icons-icon-gray {
		li {
			a {
				color: #777 !important;
			}
			&:hover {
				a {
					color: #FFF !important;
				}
			}
		}
	}
	&.social-icons-icon-light {
		li {
			a {
				i {
					color: #FFF;
				}
			}
		}
	}
	&.social-icons-icon-light.social-icons-clean {
		li {
			a {
				i {
					color: #FFF !important;
				}
			}
		}
	}
	&.social-icons-medium {
		li {
			a {
				height: 38px;
			    line-height: 38px;
			    width: 38px;
			    font-size: 0.9rem;
			}
		}
	}
	&.social-icons-big {
		li {
			a {
				height: 48px;
				line-height: 48px;
				width: 48px;
				@media(max-width: 400px) {
					height: 38px;
					line-height: 38px;
					width: 38px;					
				}
			}
		}
	}
	&.social-icons-opacity-light {
		li {
			a {
				background: rgba(255,255,255,0.04);
			}
		}
	}
	&.social-icons-clean-with-border {
		li {
			a {
				background: transparent;
				color: #FFF !important;
			    border: 1px solid rgba(255,255,255, 0.1);
			}
		}
		&.social-icons-clean-with-border-border-grey {
			li {
				a {
					border: 1px solid #e7e7e7;
				}
			}
		}
		&.social-icons-clean-with-border-icon-dark {
			li {
				a {
					color: #212121 !important;
				}
				&:hover {
					a {
						color: #FFF !important;
					}
				}
			}
		}

	}
}

.social-icons:not(.social-icons-opacity-light):not(.social-icons-transparent):not(.social-icons-clean):not(.social-icons-clean-with-border):not(.social-icons-dark):not(.social-icons-dark-2):not(.custom-social-icons) {
	li {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
		a {
			background: #FFF;
		}
	}
}

.social-icons-dark.social-icons-clean,
.social-icons-dark-2.social-icons-clean {
	li {
		a {
			background: transparent;
			color: #FFF !important;
		}
	}
}