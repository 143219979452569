/* Boxed Layout */
html.boxed {
	body {
		background-color: transparent;
		background-position: 0 0;
		background-repeat: repeat;
	}
	.body {
		position: relative;
		background-color: transparent;
		border-radius: 5px;
		border-top: 5px solid #CCC;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
		margin: 25px auto;
		max-width: 1200px;
		height: auto;
	}
	#header {
		.header-body {
			border-top-color: transparent;
			border-top: 0;
		}

		&.header-effect-shrink {
			.header-body {
				max-width: 1200px;
			}
		}
	}
	&:not(.sticky-header-active) {
		#header.header-effect-shrink {
			&.header-transparent:not(.header-semi-transparent) {
				.header-body {
					// top: 25px !important;
				}
			}
		}
	}
	.main {
		background-color: #FFF;
		overflow: hidden;
	}
	section.section {
		&.section-footer {
			padding-bottom: 100px;
		}
	}
	#footer {
		margin-top: 0;
		border-bottom: 4px solid $footer-bg;
		border-radius: 0 0 5px 5px;
	}
	.footer-reveal {
		max-width: 1200px;
		left: auto !important;
	}
}


/* Responsive */
@media (min-width: 1200px) {

	// Sticky Header
	html.boxed.sticky-header-active {
		#header {
			.header-body {
				width: 100%;
				max-width: 1200px;
			}
		}
	}
	html.boxed {
		.footer-reveal {
			bottom: 25px !important;
		}
	}
	
}

@media (max-width: 1199px) {
	html.boxed {
		.footer-reveal {
			border-bottom: none !important;
		}
	}
}

@media (min-width: 992px) {

	html.boxed {

		// Top Colored
		#header {
			.header-top.header-top-colored {
				margin-top: -18px;
				border-radius: 4px 4px 0 0;
			}
		}

	}

	// Sticky Header
	html.boxed.sticky-header-active {
		#header:not(.header-effect-shrink) {
			.header-body {
				position: fixed !important;
				padding-left: 15px;
				padding-right: 15px;
			}
			.header-nav-bar {
				margin: 0 -15px -9px;
			}
		}
	}

}

@media (max-width: 991px) {

	html.boxed {
		.body {
			margin: 0 auto;
			border-radius: 0;
		}
	}

}

@media (max-width: 767px) {

	/* Boxed */
	html.boxed {
		background: none !important;
	}

}