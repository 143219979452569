/* Thumbnails */

.img-thumbnail {
    border-radius: 4px;
    position: relative;
    img {
        border-radius: 4px;
    }
    .zoom {
        background: #CCC;
        border-radius: 100%;
        bottom: 8px;
        color: #FFF;
        display: block;
        height: 30px;
        padding: 6px;
        position: absolute;
        #{$right}: 8px;
        text-align: center;
        width: 30px;
        i {
            font-size: 10px;
            left: 0px;
            position: relative;
            top: -6px;
        }
    }
    &.img-thumbnail-hover-icon {
        position: relative;
        img {
            position: relative;
            z-index: 1;
        }
        &:before {
            transition: all 0.2s;
            background: rgba(0, 0, 0, 0.65);
            border-radius: 4px;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            z-index: 2;
        }
        &:after {
            transition: all 0.2s;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: #FFF;
            content: "\f067";
            font-size: 18px;
            height: 30px;
            position: absolute;
            top: 30%;
            margin-top: -15px;
            display: block;
            text-align: center;
            width: 100%;
            opacity: 0;
            z-index: 3;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
            &:after {
                top: 50%;
                opacity: 1;
            }
        }
    }
    a.img-thumbnail-hover-icon {
        display: inline-block;
    }
    &.img-thumbnail-no-borders {
        border: none;
        padding: 0;
    }
}


/* Image Gallery */

ul {
    &.image-gallery {
        list-style: none;
        margin: 0;
        padding: 0;
        .image-gallery-item {
            margin-bottom: 35px;
        }
    }
}


/* Thumbnail Gallery */

ul.thumbnail-gallery {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    li {
        display: inline-block;
        margin: side-values(10px 10px 0 0);
        padding: 0;
    }
}


/* Responsive */

@media (max-width: 991px) {
    .thumbnail {
        border: 0;
        text-align: center;
    }
}


/* Masonry */

.masonry {
    .masonry-item:not(.no-default-style) {
        width: 25%;
        &.w2 {
            width: 50%;
        }
        img {
            border-radius: 0 !important;
        }
    }
}


/* Masonry Loading */

.masonry-loader {
    height: auto;
    overflow: visible;
    position: relative;
    &:after {
        transition: all 0.5s;
        content: ' ';
        background: #FFF;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        opacity: 0
    }
    .bounce-loader {
        display: none;
    }
    &.masonry-loader-showing {
        height: 200px;
        overflow: hidden;
        &:after {
            opacity: 1;
        }
        .bounce-loader {
            display: block;
        }
    }
    &.masonry-loader-loaded {
        &:after {
            display: none;
        }
    }
}


/* Mockup */

@media (min-width: 992px) {
    .mockup-landing-page {
        margin-top: -120px;
    }
}


/* Hover Effect 3d */

.hover-effect-3d {
    transform-style: flat !important;
    .thumb-info {
        will-change: box-shadow;
        transition: box-shadow 300ms;
        &:hover {
            box-shadow: 0px 17px 20px -15px rgba($color-dark, 0.6);
        }
        .thumb-info-title {
            transition: ease-out opacity .2s !important;
        }
        .thumb-info-action {
            transition: ease-out opacity .2s !important;
            .thumb-info-action-icon {
                pointer-events: none;
            }
        }
        &.thumb-info-centered-info {
            .thumb-info-title {
                top: 47%;
            }
        }
    }
}


/* Profile Image */

.profile-image-outer-container {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    .profile-image-input {
        position: absolute;
        top: 0;
        height: 100%;
        opacity: 0;
        z-index: 3;
        cursor: pointer;
    }
    .profile-image-inner-container {
        border-radius: 50%;
        padding: 5px;
        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            border: 5px solid white;
        }
        .profile-image-button {
            position: absolute;
            z-index: 2;
            bottom: 15px;
            right: 15px;
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 50%;
            line-height: 38px;
            transition: ease all 0.1s;
        }
    }
    &:hover {
        .profile-image-button {
            transform: scale(1.1);
        }
    }
}


/* Lazy */

.lazy-bg {
    background-image: url(../../../img/lazy.png);
}


/* Thumb Gallery */

.thumb-gallery-wrapper {
    .thumb-gallery-thumbs {
        .owl-item {
            border: 2px solid #f7f7f7;
            transition: ease all 300ms;
            &:hover,
            &.selected {
                border: 2px solid #212121;
            }
        }
    }
}