/* ==========================================================================
Right-to-Left support
========================================================================== */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');
.title::after,
.lineAfter::after {
    right: 0;
}

body {
    font-family: 'Cairo', sans-serif !important;
}

.font-1 {
    font-family: 'Cairo';
    font-weight: bolder;
}

//New Added Style

.fas.fa-caret-right::before {
  content: "\f0d9";
}

em.fas.fa-caret-right,
em.fas.fa-map-marker-alt,
em.fas.fa-phone,
em.fas.fa-envelope,
em.far.fa-clock {
  right: 0;
}

em.fas.fa-caret-right + strong {
  margin-right: 10px;
}

em.fas.fa-map-marker-alt + span,
em.fas.fa-phone + span,
em.fas.fa-envelope + span {
  margin-right: 30px;
}

em.far.fa-clock + span {
  margin-right: 20px;
}

div.overflow-hidden p {
  text-align: center;
}

div.overflow-hidden h1 {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-align: right;
}

.headerTitle {
  text-align: center;
}
//End New Added Style

.p-component,
.p-dropdown-label {
    font-family: 'Cairo';
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    font-family: 'Cairo';
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-left: 0.5rem;
}

.boldFont {
    font-family: 'Cairo';
}

.navHeader,
.navbar-nav .nav-item,
.fontLinks {
    font-family: 'Cairo';
}

.navbar-brand {
    font-family: 'Cairo';
}

.p-timeline-left .p-timeline-event-content {
    text-align: left !important;
    line-height: 1.5;
}

.p-timeline-left .p-timeline-event-opposite {
    text-align: right !important;
}

.p-timeline {
    direction: ltr;
}

.p-timeline {
    direction: rtl;
}

.col,
.col-auto,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
    float: right;
}

body {
    text-align: right;
}

.navbar-nav>li {
    float: right;
}

.dl-horizontal dt {
    float: right;
}

th {
    text-align: right;
}

.dropdown-menu {
    text-align: right;
}

.modal-header .close {
    margin-left: 0rem;
}

.modal-footer {
    text-align: left;
}

.jh-create-entity {
    float: left !important;
}

.form-check-input {
    margin-left: 0rem;
    margin-right: -1.25rem;
}

.form-check-label {
    padding-left: 0rem;
    padding-right: 1.25rem;
}

.input-group .form-control:not(:last-child),
.input-group-prepend,
.input-group-btn:not(:last-child)>.btn,
.input-group-btn:not(:last-child)>.btn-group>.btn,
.input-group-btn:not(:last-child)>.dropdown-toggle,
.input-group-btn:not(:first-child)>.btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child)>.btn-group:not(:last-child)>.btn {
    @include border-right-radius($border-radius);
    @include border-left-radius(0);
}

.input-group-prepend {
    border-left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.input-group .form-control:not(:first-child),
.input-group-append,
.input-group-btn:not(:first-child)>.btn,
.input-group-btn:not(:first-child)>.btn-group>.btn,
.input-group-btn:not(:first-child)>.dropdown-toggle,
.input-group-btn:not(:last-child)>.btn:not(:first-child),
.input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
}

.form-control+.input-group-append {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-footer> :not(:first-child) {
    margin-left: 0rem;
    margin-right: 0.25rem;
}

.btn-group>.btn:first-child {
    margin-right: 0;
    &:not(:last-child):not(.dropdown-toggle) {
        @include border-right-radius($border-radius);
        @include border-left-radius(0);
    }
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
}

.page-item {
    &:first-child {
        .page-link {
            margin-right: 0;
            margin-left: -1px;
            @include border-left-radius(0);
            @include border-right-radius($border-radius);
        }
    }
    &:last-child {
        .page-link {
            @include border-right-radius(0);
            @include border-left-radius($border-radius);
        }
    }
}

// stylelint-disable declaration-no-important
// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $prop,
        $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }
                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }
                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
            }
        }
        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size,
        $length in $spacers {
            @if $size !=0 {
                .m#{$infix}-n#{$size} {
                    margin: -$length !important;
                }
                .mt#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-top: -$length !important;
                }
                .mr#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }
                .mb#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-bottom: -$length !important;
                }
                .ml#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }
            }
        }
        // Some special margin utils
        .m#{$infix}-auto {
            margin: auto !important;
        }
        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-top: auto !important;
        }
        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-left: auto !important;
        }
        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-bottom: auto !important;
        }
        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-right: auto !important;
        }
    }
}

body {
    text-align: right;
}

.input-group-rounded .input-group-append .btn {
    border-color: rgba(0, 0, 0, 0.09);
    border-radius: 2rem 0rem 0rem 2rem;
    font-size: 0.7rem;
    padding: 0.87rem;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    z-index: 3;
    -webkit-border-radius: 2rem 0rem 0rem 2rem;
    -moz-border-radius: 2rem 0rem 0rem 2rem;
    -ms-border-radius: 2rem 0rem 0rem 2rem;
    -o-border-radius: 2rem 0rem 0rem 2rem;
}

.input-group-rounded .form-control {
    border-radius: 0rem 2rem 2rem 0rem;
    border: 0;
    padding-left: 1rem;
    -webkit-border-radius: 0rem 2rem 2rem 0rem;
    -moz-border-radius: 0rem 2rem 2rem 0rem;
    -ms-border-radius: 0rem 2rem 2rem 0rem;
    -o-border-radius: 0rem 2rem 2rem 0rem;
}

.feature-box.reverse {
    text-align: left;
}

#header .header-nav-main nav>ul li a.dropdown-toggle .fa-chevron-down {
    width: 100%;
    justify-content: end;
    padding: 0 5px;
}

#header .header-nav-main nav>ul li a {
    text-align: right;
}

.nav {
    padding-right: 0;
}

#header .header-nav-features::before,
#header .header-nav-features::after {
    background: rgba(0, 0, 0, 0.03);
    content: none;
    width: 1px;
    height: 16px;
    position: absolute;
    display: block;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: 0;
}

#header .header-nav-features {
    position: relative;
    padding-right: 20px;
    margin-right: 10px;
}

#header .header-nav-features .header-nav-features-cart .cart-info .cart-qty {
    left: -10px;
    right: auto;
}
