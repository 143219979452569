/* Header Nav Main Mobile */
@media (max-width: 991px) {
	#header {

		.header-nav-main {
			position: absolute;
			background: transparent;
			width: 100%;
		    top: 100%;
		    left: 50%;
		    transform: translate3d(-50%, 0, 0);
		    &:before {
	    	    content: '';
			    display: block;
			    position: absolute;
			    top: 0;
			    left: 50%;
			    width: 100vw;
			    height: 100%;
			    background: #FFF;
			    z-index: -1;
			    transform: translateX(-50%);
		    }
			nav {
				max-height: 50vh;
				overflow: hidden;
				overflow-y: auto;
				padding: 0 15px;
				transition: ease all 500ms;
				&.collapsing {
					overflow-y: hidden;
				}
				&::-webkit-scrollbar {
				    width: 5px;
				}
				&::-webkit-scrollbar-thumb {
				    -webkit-border-radius: 0px;
				    border-radius: 0px;
				    background: rgba(#CCC, 0.5); 
				}

				> ul {
					padding-top: 15px;
					padding-bottom: 15px;
					flex-direction: column;
					li {
						border-bottom: 1px solid #e8e8e8;
						clear: both;
						display: block;
						float: none;
						margin: 0;
						padding: 0;
						position: relative;
						a {
							font-size: 13px;
							font-style: normal;
							line-height: 20px;
							padding: 7px 8px;
							margin: 1px 0;
							border-radius: 4px;
							text-align: $left;
							.fa-caret-down {
								line-height: 35px;
								min-height: 38px;
								min-width: 30px;
								position: absolute;
								#{$right}: 5px;
								text-align: center;
								top: 0;
							}
							&.dropdown-toggle {
								position: relative;
								&:after {
									content: none;
								}
								.fa-chevron-down {
									position: absolute;
									top: 0;
									#{$right}: 0;
									width: 30px;
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 0.6rem;
								}
							}
							&:active {
								background-color: #f8f9fa;
								color: inherit;
							}
						}
						&.dropdown {
							.dropdown-menu {
								background: transparent;
								padding: 0;
								margin: 0;
								font-size: 13px;
								box-shadow: none;
								border-radius: 0;
								border: 0;
								clear: both;
								display: none;
								float: none;
								position: static;
								li {
									&.dropdown-submenu {
										> a {
											position: relative;
											&:after {
												content: none;
											}
											.fa-chevron-down {
												position: absolute;
												top: 0;
												#{$right}: 0;
												width: 30px;
												height: 100%;
												display: flex;
												align-items: center;
												justify-content: center;
												font-size: 0.6rem;
											}
										}
										&.open {
											> .dropdown-menu {
												display: block;
												margin-#{$left}: 20px;
											}
										}
									}
								}
							}
							&.open {
								> .dropdown-menu {
									display: block;
									margin-#{$left}: 20px;
								}
							}
						}
						&.dropdown-mega {
							.dropdown-mega-sub-title {
								margin-top: 10px;
								display: block;
								text-align: $left;
							}
							.dropdown-mega-sub-nav {
								margin: side-values(0 0 0 20px);
								padding: 0;
								list-style: none;
								> li {
									> a {
										display: block;
										text-decoration: none;
									}
								}
							}
						}
						&:last-child {
							border-bottom: 0;
						}
					}
					> li {
						> a {
							text-transform: uppercase;
							font-weight: 700;
							margin-top: 1px;
							margin-bottom: 1px;
							color: #CCC;
							&:active {
								color: #CCC;
							}
							
							&.active {
								color: #FFF !important;
								background: #CCC;
								&:focus, &:hover {
									color: #FFF;
									background: #CCC;
								}
							}
						}
					}
				}

				.not-included {
					margin: 0;
				}
			}

			// Thumb Preview
			a {
				> .thumb-info-preview {
					display: none !important;
				}
			}

			// Header Nav Main Square
			&.header-nav-main-square {
				nav {
					> ul {
						> li {
							a {
								border-radius: 0 !important;
							}
						}
					}
				}
			}

			// Header Nav Slide
			&.header-nav-main-slide {
				.wrapper-items-cloned {
					display: none;
				}
			}
		}

		// Header Navbar
		.header-nav-bar {
			.header-container {
				min-height: 0;
				height: auto !important;
			}
			.header-nav {
				min-height: 60px !important;
				padding: 0;
			}
		}

		.header-btn-collapse-nav {
			outline: 0;
			display: block;
		    position: relative;
		    z-index: 1;
		}

		// Header Nav Styles
		.header-nav {
			&.header-nav-stripe {
				min-height: 90px;
			}

			&.header-nav-links,
			&.header-nav-line {
				min-height: 70px;
			}

			&.header-nav-dropdowns-dark {
				min-height: 70px;
			}

			&.header-nav-sub-title,
			&.header-nav-sub-title-animated {
				.header-nav-main {
					nav {
						> ul {
							> li {
								> a {
								    > span {
								    	display: block;
								    	text-transform: none;
								    	font-weight: normal;
								    }
								}
							}
						}
					}
				}
			}
		}

	}

}

/* Header Nav Main Mobile Dark */
@media (max-width: 991px) {
	#header {

		// Header Nav Main
		.header-nav-main {
			&.header-nav-main-mobile-dark {
				&:before {
					background-color: $color-dark;
				}
				nav {
					> ul { 
						> li {
							> a {
								color: #dedede;
								&.active {
									color: #FFF;
								}
							}
							.dropdown-menu {
								> li {
									a {
										color: #969696;
										&.active {
											color: #FFF;
										}
									}
								}
							}
						}
						li {
						    border-bottom: 1px solid #3e3e3e;
						    &:last-child {
						    	border-bottom: 0;
						    }
						    a {
						    	&:hover, &:focus, &:active {
									background-color: #2b2b2b;
						    	}
						    }

						    &.dropdown-mega {
						    	.dropdown-mega-sub-title {
						    		color: #eaeaea;
						    	}
						    }
						}
					}
				}
			}
		}

	}
}

/* Header Nav Main Dark Text */
@media (max-width: 991px) {
	#header {

		// Header Nav Main
		.header-nav-main {
			&.header-nav-main-dark-text {
				nav {
					> ul { 
						> li {
							> a {
								color: #333;
								&.active {
									color: #FFF;
								}
							}
						}
					}
				}
			}
		}

	}
}

/* Header Nav Vertical */
@media (max-width: 991px) {
	html.side-header {

		#header.side-header {

			.side-header-scrollable {
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
				overflow: visible;
				.scrollable-content {
					right: 0 !important;
					overflow: visible;
				}
				.scrollable-pane {
					display: none !important;
				}
			}

		}
	}
}

/* Side Header Hamburguer Sidebar */
@media (max-width: 991px) {

	html.side-header-hamburguer-sidebar {

		#header.side-header {

			.header-row-side-header {
				width: 100%;
			}

			.side-header-scrollable {
				height: 100%;
				.scrollable-content {
					right: 0 !important;
					padding-#{$right}: 10px;
					padding-#{$left}: 10px;
					overflow-y: hidden;
				}
				.scrollable-pane {
					display: none !important;
				}
			}

			.header-nav {
				height: 100%;

				.header-nav-main {
					position: relative;
				    top: 0;
				    left: 0;
				    transform: none;

				    // Header Nav Main Mobile Dark
				}

				nav {
					display: block !important;
					max-height: 69vh;
					padding: 0 8px 0 0;
				}

				&.header-nav-links {
					width: 100%;
					padding: 0;
					overflow: hidden;
					.header-nav-main {
						align-self: stretch;
						min-height: 0;
					    margin-top: 0;
						
						// Header Nav Main Mobile Dark
					    &.header-nav-main-mobile-dark {
					    	nav {
					    		> ul {
					    			> li {
					    				> a {
											color: #dedede !important;
											border-bottom-color: #3e3e3e;
											&.active {
												color: #FFF !important;
											}
					    				}
					    				.dropdown-menu {
											> li {
												a {
													color: #969696;
													&.active {
														color: #FFF;
													}
												}
											}
										}
					    			}
					    			li {
									    border-bottom-color: #3e3e3e;
									    a {
									    	&:hover, &:focus, &:active {
												background-color: #2b2b2b;
									    	}
									    }
									}
					    		}
					    	}
					    }
					}
					nav {
						> ul {
							> li, > li:hover {
								> a {
									position: relative;
									background: transparent !important;
									color: #444 !important;
									padding: 0 .9rem;
									margin: 1px 0 0;
									min-height: 70px;
									height: 100%;
								}
							}
							> li {
								> a {
									&.dropdown-toggle {
										&:after {
											content: none;
										}
									}

									&.active {
										color: #CCC;
										background: transparent;
									}
								}

								&.dropdown {
									&.open, &:hover {
										> .dropdown-menu {
											margin-top: 0;
										}
									}
								}
							}
							li {
								border-bottom: 0;
								a {
									color: #444;
									font-size: 12px;
								}
							}
						}
					}

				}

				// Header Nav Links Side Header
				&.header-nav-links-side-header {
					nav {
						> ul {
							> li {
								> a {
									padding: 10px 15px !important;
									min-height: 0 !important;
									border-bottom: 1px solid #f7f7f7;
									border-radius: 0;
								}
								&:last-child {
									> a {
										border-bottom: 0;
									}
								}
								&.dropdown {
									position: static
								}
							}
						}
					}
				}

				// Header Nav Links Vertical Expand
				&.header-nav-links-vertical-expand {
					nav {
						> ul {
							> li.dropdown {
								> .dropdown-menu {
									li {
										a {
											border: 0;
										}
									}
								}
								&:not(.open) {
									.dropdown-menu {
										overflow: hidden;
										opacity: 0;
										width: 0;
										height: 0;
										top: -10000px;
									}
								}
								&.open {
									> .dropdown-menu,
									> .dropdown-menu li.dropdown-submenu.open > .dropdown-menu {
										position: relative;
										box-shadow: none;
										margin: 0;
										padding: 5px 0 5px 15px;
										top: auto;
										left: auto;
										right: auto;
										transform: none;
										min-width: auto;
										width: 100%;
										opacity: 1 !important;
									}
								}
								&.dropdown-mega {
									.dropdown-menu {
										min-width: 0;
									}
									.dropdown-mega-content {
										padding: 5px 0 5px 15px;
										[class*=col] {
											max-width: 100%;
											display: block;
											padding: 5px 0;
											flex: none;
										}
										.dropdown-mega-sub-title {
											margin-top: 0;
											padding-left: 15px;
											color: #333333;
										    font-size: 0.85em;
										    font-weight: 600;
										    text-transform: uppercase;
										    letter-spacing: -0.5px;
										}
										.dropdown-mega-sub-nav {
											padding-left: 10px;
										}
									}
								}
							}

						}
					}
					.thumb-info-preview {
						display: none;
					}
				}

			}
		}
	}
}