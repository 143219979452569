/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop {
	.dropdown-menu {
		width: 40%;
		#{$right}: 15px;
		#{$left}: auto;
		border-radius: corner-values(6px 0 6px 6px);
		li {
			table {
				width: 100%;
				margin-top: 0;
				tr {
					td {
						a {
							background-color: transparent !important;
							color: #333;
							padding: 0;
							margin: 0;
							display: block;
							&.remove {
								float: $right;
							}
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
	.fa-shopping-cart {
		margin-#{$right}: 5px;
	}
	.product-thumbnail {
		width: 120px;
	}
	.product-actions {
		text-align: $right;
		width: 80px;
	}
	.actions-continue {
		margin-top: 10px;
		padding-top: 20px;
		border-top: 1px solid #DDD;
		clear: both;
		.btn {
			margin-bottom: 10px;
		}
	}
}

/* Responsive */
@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop {
		.dropdown-menu {
			margin: 15px 0;
			padding: 0 15px;
			width: 100%;
		}
	}
}