/* Testimonials */

.testimonial {
    margin-bottom: $spacement-lg;
    blockquote {
        background: #CCC;
        border-radius: 5px;
        border: 0;
        color: #666;
        // font-family: 'Playfair Display', serif;
        margin: 0;
        padding: 2.5rem 4rem 2.5rem 4.5rem;
        position: relative;
        &:before {
            #{$left}: 22px;
            top: 21px;
            color: #FFF;
            content: if-ltr("\201C", "\201D");
            font-size: 85px;
            font-style: normal;
            line-height: 1;
            position: absolute;
        }
        p {
            color: #FFF;
            // font-family: 'Playfair Display', serif;
            font-size: 1.2em;
        }
    }
    .testimonial-arrow-down {
        border-#{$left}: 11px solid transparent;
        border-#{$right}: 11px solid transparent;
        border-top: 8px solid #CCC;
        height: 0;
        margin: side-values(0 0 0 45px);
        width: 0;
    }
    .testimonial-author {
        display: flex;
        margin: 12px 0 0 0;
        .testimonial-author-thumbnail {
            width: auto;
        }
        img {
            max-width: 25px;
        }
        strong {
            color: #111;
            display: block;
            margin-bottom: 2px;
            font-size: 1rem;
        }
        span {
            color: #666;
            display: block;
            font-size: 0.8rem;
            font-weight: 600;
        }
        p {
            color: #999;
            text-align: $left;
            line-height: 1.2;
            margin-#{$left}: 0.6rem;
        }
    }
}


/* Style 2 */

.testimonial {
    &.testimonial-style-2 {
        text-align: center;
        blockquote {
            background: transparent !important;
            border-radius: 4px;
            padding: 22px 20px;
            &:before,
            &:after {
                display: none;
            }
            p {
                color: $color-font-default;
            }
        }
        .testimonial-arrow-down {
            display: none;
        }
        .testimonial-author {
            padding: 0;
            margin: 6px 0 0;
            flex-direction: column;
            img {
                margin: 0 auto 15px;
                max-width: 60px;
                max-height: 60px;
            }
            p {
                text-align: center;
                padding: 0;
                margin: 0;
            }
        }
    }
}


/* Style 3 */

.testimonial {
    &.testimonial-style-3 {
        blockquote {
            background: $color-default !important;
            border-radius: 4px;
            padding: 12px 30px;
            &:before {
                top: 5px;
                left: 9px;
                color: $color-font-default;
                font-size: 35px;
            }
            &:after {
                color: $color-font-default;
                font-size: 35px;
                bottom: -5px;
            }
            p {
                color: $color-font-default;
                font-family: $font-primary;
                font-size: 1em;
                font-style: normal;
            }
        }
        .testimonial-author {
            margin-#{$left}: 8px;
            align-items: center;
            img {
                max-width: 55px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .testimonial-arrow-down {
            margin-#{$left}: 20px;
            border-top: 10px solid $color-default !important;
        }
    }
}


/* Style 4 */

.testimonial {
    &.testimonial-style-4 {
        border-radius: 6px;
        padding: 8px;
        border-top: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF;
        border-#{$left}: 1px solid #ECECEC;
        border-#{$right}: 1px solid #ECECEC;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
        blockquote {
            background: transparent !important;
            border-radius: 4px;
            padding: 12px 30px;
            &:before {
                top: 5px;
                #{$left}: 9px;
                color: $color-font-default;
                font-size: 35px;
            }
            &:after {
                color: $color-font-default;
                font-size: 35px;
                bottom: -5px;
            }
            p {
                color: $color-font-default;
                font-family: $font-primary;
                font-size: 1em;
                font-style: normal;
            }
        }
        .testimonial-arrow-down {
            display: none;
        }
        .testimonial-author {
            margin-#{$left}: 30px;
            align-items: center;
            img {
                max-width: 55px;
            }
            p {
                margin-bottom: 0;
            }
        }
        &.testimonial-no-borders {
            border: 0;
            box-shadow: none;
        }
    }
}


/* Style 5 */

.testimonial {
    &.testimonial-style-5 {
        text-align: center;
        blockquote {
            background: transparent !important;
            border-radius: 4px;
            padding: 6px 20px;
            &:before,
            &:after {
                display: none;
            }
            p {
                color: $color-font-default;
                font-family: $font-primary;
                font-size: 1.2em;
                font-style: normal;
            }
        }
        .testimonial-arrow-down {
            display: none;
        }
        .testimonial-author {
            display: block;
            padding: 6px 0 0;
            margin: 12px 0 0;
            border-top: 1px solid $color-default;
            justify-content: center;
            img {
                margin: 0 auto 10px;
                max-width: 55px;
            }
            p {
                text-align: center;
                padding: 0;
                margin: 0;
            }
        }
    }
}


/* Style 6 */

.testimonial {
    &.testimonial-style-6 {
        text-align: center;
        blockquote {
            background: transparent !important;
            border-radius: 4px;
            border: 0;
            padding: 0 10%;
            &:before,
            &:after {
                display: none;
            }
            i.fa-quote-left {
                font-size: 34px;
                position: relative;
                #{$left}: -5px;
            }
            p {
                color: $color-font-default;
                font-size: 24px;
                line-height: 30px;
            }
            span {
                font-size: 16px;
                line-height: 20px;
                color: #999;
                position: relative;
                #{$left}: -5px;
            }
        }
        .testimonial-arrow-down {
            display: none;
        }
        .testimonial-author {
            padding: 0;
            margin: 6px 0 0;
            justify-content: center;
            img {
                margin: 0 auto 10px;
            }
            p {
                text-align: center;
                padding: 0;
                margin: 0;
            }
        }
    }
}


/* Style 7 */

.testimonial {
    &.testimonial-style-7 {
        text-align: center;
        background-color: #CCC;
        padding: 20px;
        blockquote {
            padding: 30px;
            &:before {
                color: #FFF;
                display: block !important;
                left: 50%;
                top: -10px;
                font-size: 80px;
                font-style: normal;
                line-height: 1;
                position: absolute;
                transform: translate3d(-50%, 0, 0);
            }
            &:after {
                display: none;
            }
        }
        .testimonial-arrow-down {
            display: none;
        }
        .testimonial-author {
            padding: 0;
            margin: 6px 0 0;
            justify-content: center;
            img {
                margin: 0 auto 10px;
            }
            p {
                text-align: center;
                padding: 0;
                margin: 0;
            }
        }
    }
}


/* With Quotes */

.testimonial {
    &.testimonial-with-quotes {
        blockquote {
            &:before {
                color: $color-font-default;
                display: block !important;
                #{$left}: 10px;
                top: 0;
                content: if-ltr("\201C", "\201D");
                font-size: 80px;
                font-style: normal;
                line-height: 1;
                position: absolute;
            }
            &:after {
                color: $color-font-default;
                display: block !important;
                #{$right}: 10px;
                font-size: 80px;
                font-style: normal;
                line-height: 1;
                position: absolute;
                bottom: -0.5em;
                content: if-ltr("\201D", "\201C");
            }
            p {
                padding: 0 40px;
            }
        }
        /* Remove Left Quote */
        &.testimonial-remove-left-quote {
            blockquote {
                &:before {
                    content: none;
                }
            }
        }
        /* Remove Right Quote */
        &.testimonial-remove-right-quote {
            blockquote {
                &:after {
                    content: none;
                }
            }
        }
        &.testimonial-quotes-dark {
            blockquote {
                &:before,
                &:after {
                    color: $color-dark !important;
                }
            }
        }
    }
}


/* Transparent Background */

.testimonial {
    &.testimonial-trasnparent-background {
        blockquote {
            background: transparent !important;
        }
    }
}


/* Alternarive Font */

.testimonial {
    &.testimonial-alternarive-font {
        blockquote {
            p {
                font-family: 'Playfair Display', serif;
                font-style: italic;
                font-size: 1.2em;
            }
        }
    }
}


/* Light */

.testimonial {
    &.testimonial-light {
        blockquote {
            &:before,
            &:after,
            p {
                color: #FFF;
            }
            &.blockquote-default {
                &:before,
                &:after,
                p {
                    color: #777 !important;
                }
            }
        }
        .testimonial-author {
            span,
            strong {
                color: #FFF;
            }
        }
    }
}

.section-text-light {
    .testimonial {
        &.testimonial {
            blockquote {
                &:before,
                &:after,
                p {
                    opacity: 0.9;
                    color: #FFF;
                }
            }
            .testimonial-author {
                span,
                strong {
                    opacity: 0.6;
                    color: #FFF;
                }
            }
        }
    }
}