html,
body {
    height: 100%;
}

#header .header-nav-main nav>ul>li.dropdown .dropdown-menu li a:hover,
#header .header-nav-main nav>ul>li.dropdown .dropdown-menu li a:focus,
#header .header-nav-main nav>ul>li.dropdown .dropdown-menu li a.active,
#header .header-nav-main nav>ul>li.dropdown .dropdown-menu li a:active,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li:hover>a,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li:focus>a,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li:active>a,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li.active>a,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li.open>a,
#header .header-nav.header-nav-dropdowns-dark nav>ul>li.dropdown .dropdown-menu>li.accessibility-open>a {
    background-color: $primary;
    color: $light;
}

#header .header-nav-main nav>ul li a.dropdown-toggle .fa-chevron-down {
    width: 100%;
    justify-content: end;
    text-align: right;
    padding: 0 5px;
}

#header .header-nav.header-nav-links.header-nav-light-text nav>ul>li:hover>a,
#header .header-nav.header-nav-line.header-nav-light-text nav>ul>li:hover>a {
    color: $primary;
}

#header .header-nav.header-nav-links .dropdown-toggle:focus {
    color: $primary;
}

html:not(.sticky-header-active) #header.header-transparent:not(.header-semi-transparent).home .header-body {
    background-color: transparent !important;
}
